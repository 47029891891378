import React, { useCallback } from "react"
import styled from "styled-components"

import { Chip, Modal } from "../../components"

const Container = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;

  .title {
    font-size: 20px;
  }

  .chip-container {
    display: flex;
    align-items: flex-start;
  }

  p {
    font-size: 12px;
    margin: 0;
  }
`

const ReportTable = styled.div`
  grid-column: span 2;
  display: grid;
  grid-template-columns: repeat(3, 1fr) minmax(min-content, max-content);
  grid-column-gap: 10px;

  p {
    padding: 5px;
  }

  .table-heading {
    line-height: 15px;
    font-weight: 500;
  }

  hr {
    margin: 5px 0px;

    &.primary {
      grid-column: 1 / -1;
      border-bottom: 1px solid ${({ theme }) => theme.palette.mineShaft};
    }

    &.secondary {
      grid-column: -5 / -1;
      border-bottom: 1px solid ${({ theme }) => theme.palette.porcelain};
    }
  }

  .total-column {
    text-align: right;
  }
`

const TotalHeader = styled.h5`
  grid-column: -4 / -3;
  font-size: 13px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 5px;
`

const TotalValue = styled.h6`
  grid-column: -3 / -1;
  font-size: 26px;
  font-weight: 700;
  text-align: right;
  margin-top: 20px;
  margin-bottom: 5px;
`

interface Props {
  open: boolean
  onClose: () => void
  report: Reseller.AssociatePerformanceReport
}

const PerformanceReportModal: React.FC<Props> = ({
  open,
  onClose,
  report,
}: Props) => {
  const renderReportLineItems = useCallback(
    () =>
      report.lineItems?.map((item, index) => {
        return (
          <>
            <p>
              {item.adHocCategory
                ? `${item.adHocCategory + ": " + item.description}`
                : item.description}
            </p>
            <p>{item.quantity ? item.quantity : item.percentage + "%"}</p>
            <p>{item.itemAmount ? `R${item.itemAmount}` : "-"}</p>
            <p className="total-column">R{item.totalAmount.toFixed(2)}</p>
            <hr
              className={
                index !== report.lineItems?.length - 1 ? "secondary" : "primary"
              }
            />
          </>
        )
      }),
    [report.lineItems]
  )

  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <h3 className="title">{`Report ${report.createdAt}`}</h3>
        <div className="chip-container">
          <Chip
            size="large"
            text={report.status ? report.status : ""}
            backgroundColor="#dff5fb"
            textColor="#00a9e0"
            borderColor="transparent"
          />
        </div>
        <p>Issued: {report.createdAt}</p>
        <ReportTable>
          <p className="table-heading">Description</p>
          <p className="table-heading">Units</p>
          <p className="table-heading">Amount</p>
          <p className="table-heading total-column">Total</p>
          <hr className="primary" />
          {renderReportLineItems()}
          <TotalHeader>Total</TotalHeader>
          <TotalValue>R {report.totalAssociateCut}</TotalValue>
        </ReportTable>
      </Container>
    </Modal>
  )
}

export default PerformanceReportModal
