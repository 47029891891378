import { Link } from "gatsby"
import React, { useState, useMemo, useCallback } from "react"
import styled from "styled-components"

import PerformanceReportModal from "../../associate-performance/components/PerformanceReportModal"
import { useGetPayrolls, useUser } from "../../auth/hooks"
import {
  PageHeader,
  PageGuard,
  PageNotificationBanner,
  Filter,
  Table as TableBase,
  Chip,
  TableButton,
} from "../../components"
import Seo from "../../components/seo"
import { TableHeading } from "../../components/Table"
import { formatMonthToString } from "../../utils/date"

const Table = styled(TableBase)`
  margin: 20px 0;

  .table-row {
    grid-template-areas:
      "date status"
      "amountOwed action";

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      grid-template-areas: "date status amountOwed action";
    }
  }
`

const ChipContainer = styled.div`
  display: flex;
  grid-area: status;
  justify-self: end;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-self: start;
  }
`

const RowTextField = styled.p<{
  gridArea: string
}>`
  grid-area: ${({ gridArea }) => gridArea};
  padding-right: 5px;
  text-align: left;
`

const PerformanceReportStatus = {
  PAID: "PAID",
  UNPAID: "UNPAID",
}

interface FilterOption {
  title: string
  filterRef?: Reseller.PayrollStatusType
  quantity?: number
}

const renderChip = (status: string, index: number) => {
  const borderColor =
    status === PerformanceReportStatus.PAID
      ? "#e1eae8"
      : status === PerformanceReportStatus.UNPAID
      ? "#ffd4c6"
      : ""

  const textColor =
    status === PerformanceReportStatus.PAID
      ? "#00a9e0"
      : status === PerformanceReportStatus.UNPAID
      ? "#fa5d37"
      : ""

  const text =
    status === PerformanceReportStatus.PAID
      ? "Paid"
      : status === PerformanceReportStatus.UNPAID
      ? "Unpaid"
      : ""

  return (
    <ChipContainer key={`chip-container-${index}`}>
      <Chip
        text={text}
        backgroundColor="inherit"
        borderColor={borderColor}
        borderWidth="2px"
        textColor={textColor}
        size="medium"
        fontWeight="500"
      />
    </ChipContainer>
  )
}

const AssociatePerformance = (): React.ReactElement => {
  const { data, loading, refetch, fetchMore } = useGetPayrolls()
  const { user } = useUser()
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0)
  const [reportModalOpen, setReportModalOpen] = useState(false)
  const [selectedReportIndex, setSelectedReportIndex] = useState(0)
  const reports: Reseller.AssociatePerformanceReport[] = data?.edges.map(
    (payroll: { node: Reseller.Payroll }) => {
      return {
        status: payroll.node.status,
        createdAt: payroll.node.createdAt,
        totalAssociateCut: payroll.node.totalAssociateCut,
        lineItems: payroll.node.lineItems,
      }
    }
  )

  const performanceReportsFilters: FilterOption[] = useMemo(
    () => [
      {
        title: "All Reports",
        filterRef: undefined,
        quantity: data?.count?.all,
      },
      {
        title: "Unpaid",
        filterRef: "UNPAID",
        quantity: data?.count?.unpaid,
      },
      {
        title: "Paid",
        filterRef: "PAID",
        quantity: data?.count?.paid,
      },
    ],
    [data]
  )

  const handleFilterChange = (index: number) => {
    refetch({
      status: performanceReportsFilters[index].filterRef,
    })
    setSelectedFilterIndex(index)
  }

  const handleFetchMoreReports = useCallback(() => {
    fetchMore({
      variables: {
        after: data?.pageInfo.endCursor,
      },
    })
  }, [fetchMore, data?.pageInfo.endCursor])

  const tableHeadings: TableHeading[] = useMemo(
    () => [
      {
        name: "Reporting month",
        accessor: "createdAt",
        columnAllowance: "1fr",
        renderData: (index: number, createdAt: string) => {
          const date = new Date(createdAt)
          const formattedDate = `${formatMonthToString(
            date,
            true
          )} ${date.getFullYear()}`

          return (
            <div
              key={`date-issued-${index}`}
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                gridArea: "date",
              }}
            >
              <img src="/images/icons/icon-date.svg" alt="Date icon" />
              <p>{formattedDate}</p>
            </div>
          )
        },
      },
      {
        name: "Status",
        accessor: "status",
        columnAllowance: "1fr",
        renderData: (index: number, status: string) =>
          renderChip(status, index),
      },
      {
        name: "Amount Owed",
        accessor: "totalAssociateCut",
        columnAllowance: "1fr",
        renderData: (index: number, totalAssociateCut: number) => (
          <RowTextField key={`totalAmount-${index}`} gridArea={"amountOwed"}>
            R{totalAssociateCut}
          </RowTextField>
        ),
      },
      {
        name: "",
        accessor: "status",
        columnAllowance: "1fr",
        renderData: (index: number, status: string) => (
          <TableButton
            key={`view-button-${status}`}
            label="View"
            color="transparent"
            textColor="#222"
            borderColor="rgba(112,153,144,0.4)"
            onClick={() => {
              setSelectedReportIndex(index)
              setReportModalOpen(true)
            }}
          />
        ),
      },
    ],
    []
  )

  return (
    <PageGuard verifyUserProfile allowedProfileTypes={["ASSOCIATE"]}>
      <Seo title="Performance Reports" />
      <PageHeader
        cypressTestId="performance-reports-page-header"
        title="Performance Reports"
      />
      <PageNotificationBanner>
        {user?.profile?.verificationStatus !== "SUCCESSFUL" ? (
          <p>
            Your identity has not been verified yet. Please{" "}
            <Link className="highlighted" to="/onfido/">
              verify your identity
            </Link>{" "}
            to list your performance reports.
          </p>
        ) : (
          <p>View and manage your performance reports</p>
        )}
      </PageNotificationBanner>

      {user?.profile?.verificationStatus === "SUCCESSFUL" ? (
        <>
          {" "}
          <Filter
            isPageFilter
            filterOptions={performanceReportsFilters}
            onClick={handleFilterChange}
            activeIndex={selectedFilterIndex}
          />
          <Table
            headings={tableHeadings}
            rowData={reports}
            loading={loading}
            emptyTableText="You have no performance reports to view"
            allRowsLoaded={!data?.pageInfo.hasNextPage}
            handleLoadMoreRows={handleFetchMoreReports}
          />
          {!loading && reports?.length > 0 ? (
            <PerformanceReportModal
              open={reportModalOpen}
              onClose={() => setReportModalOpen(false)}
              report={reports[selectedReportIndex]}
            />
          ) : null}
        </>
      ) : null}
    </PageGuard>
  )
}

export default AssociatePerformance
